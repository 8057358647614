import './App.css';
import React, { useState } from 'react';

import EmilyBackground from './Emily.png';
import HeaderTitle from './components/headerTitle/HeaderTitle';
import NavigationBar from './components/NavigationBar/NavigationBar';
import Music from './components/Music/Music';
import LinkFooter from "./components/LinkFooter/LinkFooter";
import Contact from "./components/Contacts/Contact"
import { set } from 'react-hook-form';

function App() {

  const [music, setMusic] = useState(false);
  const [contact, setContact] = useState(false);


  //handle navbar clicks
  function handleClick (e) {
    console.log('yy')
    if (e.target.name === "music") {

      if(!music) {
        setMusic(true);
        setContact(false);
      } else {
        setMusic(false);
      }

      console.log(music)
    } else if (e.target.name === "contact") {
      if(!contact) {
        setContact(true);
        setMusic(false);
      } else {
        setContact(false);
      }
    }
  }

  return (
    <div className="App"  style={{backgroundImage: `url(${EmilyBackground})`, backgroundRepeat: "no-repeat center fixed",  backgroundSize: "cover", backgroundPosition: "center center"}}>
        <HeaderTitle ></HeaderTitle>
        <NavigationBar handleClick={handleClick}></NavigationBar>

        <Music clicked={music}></Music>
        <Contact clicked={contact}></Contact>

        <LinkFooter></LinkFooter>


    </div>
  );
}

export default App;
