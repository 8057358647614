import './HeaderTitle.css'
import Navbar from 'react-bootstrap/Navbar'

function HeaderTitle() {
    return (
        <div className="HeaderTitle align-top justify-content-center d-flex" >
            <Navbar className="fontCursive align-top" >
            Emily Ellis
            </Navbar>
        </div>
    );
}

export default HeaderTitle;
