import './Music.css'
import Navbar from 'react-bootstrap/Navbar'
import { Container } from 'react-bootstrap';

function Music(props) {



    if (props.clicked) {
        return (
            <Container className="align-top MusicStyle" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} height={150} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/hard-for-you-to-love-me/1589856287?i=1589856288" />
               <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} height={150} style={{ width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/stars-and-skies/1585722032?i=1585722033" />
            </Container>
        );
    } else {
        return (
            <Container>
                
            </Container>
        )
    }
    }


export default Music;
