import './NavigationBar.css'
import Navbar from 'react-bootstrap/Navbar'
import { Container, Nav } from 'react-bootstrap';

function NavigationBar(props) {



    return (
        <div className="NavigationBar align-top NavStyles" >
            <Navbar bg="dark" variant="dark" className="align-top" >
                <Container>
                    <Nav.Link className="NavText" name="music" onClick={ props.handleClick } href="#">Music</Nav.Link>
                    <Nav.Link className="NavText" name="contact" onClick={ props.handleClick } href="#">Contact</Nav.Link>
                </Container>
            </Navbar>
        </div>
    );
}

export default NavigationBar;
