import './LinkFooter.css'
import Navbar from 'react-bootstrap/Navbar'
import { Container, Nav } from 'react-bootstrap';
import { FaYoutube, FaBandcamp, FaSoundcloud, FaSpotify, FaApple, FaItunes } from "react-icons/fa";

function LinkFooter() {
    return (
            <Navbar className="FooterStyles">
                <Container>
                    <Nav.Link className="FooterItems" href="https://www.youtube.com/channel/UC63xHV5y-E9YKbzJRKCp_MQ"><FaYoutube></FaYoutube></Nav.Link>
                    <Nav.Link className="FooterItems" href="https://emilyellis.bandcamp.com"><FaBandcamp></FaBandcamp></Nav.Link>
                    <Nav.Link className="FooterItems" href="https://soundcloud.com/emilyellismusic"><FaSoundcloud></FaSoundcloud></Nav.Link>
                    <Nav.Link className="FooterItems" href="https://open.spotify.com/artist/1CCbmV2BgGPrFxroJ4WYLh?si=xmRzKk9SST6dqhErywrcQg&dl_branch=1"><FaSpotify></FaSpotify></Nav.Link>
                    <Nav.Link className="FooterItems" href="https://music.apple.com/us/artist/emily-ellis/1585700736"><FaApple></FaApple></Nav.Link>
                </Container>
            </Navbar>
    );
}

export default LinkFooter;
