import React, { useState } from 'react';


import './Contact.css'
import { useForm } from 'react-hook-form';
import { Container, Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar'
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";

import{ init, sendForm} from 'emailjs-com';
init("user_FCgcdFPJ6qcMG3VcevTDg");



function Contact(props) {

    //for emailjs
    const [contactNumber, setContactNumber] = useState("000000");
    const [statusMessage, setStatusMessage] = useState("Message");
    
    const generateContactNumber = () => {
        const numStr = "000000" + (Math.random() * 1000000 | 0);
        setContactNumber(numStr.substring(numStr.length - 6));
    }

    //vars for Form
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        const form = document.querySelector('#contact-form');
        const statusMessage = document.querySelector('.status-message');


        // console.log(data);
        generateContactNumber();
        sendForm('default_service', 'template_vz28m3o', '#contact-form')
          .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
            form.reset();
            setStatusMessage("Message sent!");
            statusMessage.className = "status-message success";
            setTimeout(()=> {
              statusMessage.className = 'status-message'
            }, 5000)
          }, function(error) {
            console.log('FAILED...', error);
            setStatusMessage("Failed to send message! Please try again later.");
            statusMessage.className = "status-message failure";
            setTimeout(()=> {
              statusMessage.className = 'status-message'
            }, 5000)
          });
      }    
      
    const message = watch('message') || "";
    const messageCharsLeft = 1500 - message.length;


    console.log(watch("example")); // watch input value by passing the name of it


    if (props.clicked) {
        return (
            <div className="divStyle">
                <p className='status-message'>{statusMessage}</p>

                {/* form for contact */}
                <form id='contact-form'  onSubmit={handleSubmit(onSubmit)}>

                {/* hidden contact number */}
                <input type='hidden' name='contact_number' value={contactNumber} />

                <input 
                    type='text' 
                    name='user_name' 
                    placeholder='Name' 
                    {...register("user_name", { required: true })}
                    className="inputStyles"/>
                <br/>
                {errors.user_name && <span className="errorMessage">A name is required</span>}
                <br/>

                <input 
                    type='email' 
                    name='user_email' 
                    placeholder='Email' 
                    {...register("user_email", { required: true })} 
                    className="inputStyles"/>
                <br/>
                {errors.user_email && <span className="errorMessage">An email is required</span>}
                <br/>

                <textarea 
                    name='message' 
                    placeholder='Message' 
                    maxLength='1500'
                    {...register("message", { required: true })}
                    className="inputStyles"/>
                <p className='message-chars-left'>{messageCharsLeft}</p>

                <br/>
                {errors.message && <span className="errorMessage">A message is required</span>}
                <br/>

                <input type='submit' value='Send' id="submitButton" />
                </form>

                <Container className="containerStyle">
                    <Nav.Link className="socialLink" href="https://www.facebook.com/emilyellismusic"><FaFacebookSquare></FaFacebookSquare></Nav.Link>
                    <Nav.Link className="socialLink" href="https://www.instagram.com/emilyellismusic/"><FaInstagram></FaInstagram></Nav.Link>
                </Container>

            </div>
        );
    } else {
        return (
            <Container>

            </Container>
        );
    }

}

export default Contact;
